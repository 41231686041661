import React from 'react';
import Fab from '@mui/material/Fab';
import NavigationIcon from '@mui/icons-material/Navigation';
import { Box } from '@mui/material';

function ActionButton() {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 16,
        right: 16,
      }}
    >
      <Fab variant="extended" color="primary" onClick={handleClick}>
        <NavigationIcon sx={{ mr: 1 }} />
        Back to Top
      </Fab>
    </Box>
  );
}

export default ActionButton;
