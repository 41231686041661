import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '400px',
};

const center = {
  lat: 43.3053, // Replace with the latitude of your meeting location
  lng: -70.9782, // Replace with the longitude of your meeting location
};

function MeetingInfo() {
  return (
    <Box my={4}>
      <Container>
        <Typography variant="h4" component="h2" gutterBottom>
          Zoning Board Meeting
        </Typography>
        <Box display="flex" alignItems="center" mb={2}>
          <CalendarTodayIcon fontSize="large" />
          <Typography variant="h6" component="p" ml={1}>
            Date: Wednesday, August 14, 2024 - 7:00pm
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" mb={2}>
          <LocationOnIcon fontSize="large" />
          <Typography variant="h6" component="p" ml={1}>
            Location: Council Chambers - 31 Wakefield Street. Rochester, NH. 03867.
          </Typography>
        </Box>
        <Box mb={2}>
          <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={15}
            >
              <Marker position={center} />
            </GoogleMap>
          </LoadScript>
        </Box>
        <Typography variant="body1" mt={2}>
          Join us at the next Zoning Board meeting to voice your concerns about the proposed campground. Your presence and input are crucial in protecting our community.
        </Typography>
      </Container>
    </Box>
  );
}

export default MeetingInfo;
