import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function Concern({ title, content }) {
  return (
    <Box my={4}>
      <Container>
        <Typography variant="h4" component="h2" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body1">
          {content}
        </Typography>
      </Container>
    </Box>
  );
}

export default Concern;
