import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

function HeroSection() {
  return (
    <Box bgcolor="primary.main" color="white" py={5}>
      <Container>
        <Typography variant="h2" component="h1" gutterBottom>
          Say NO to the Proposed Campground!
        </Typography>
        <Typography variant="h5">
          Join us in protecting our community from unwanted development.
        </Typography>
      </Container>
    </Box>
  );
}

export default HeroSection;
