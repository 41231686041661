import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function Footer() {
  return (
    <Box bgcolor="text.secondary" color="white" py={3}>
      <Container>
        <Typography variant="body1" align="center">
          Join us at the next City Council meeting to voice your concerns.
        </Typography>
        <Typography variant="body2" align="center">
          © 2024 Protect Our Community
        </Typography>
      </Container>
    </Box>
  );
}

export default Footer;
