import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function SiteDetails() {
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState('');

  const handleOpen = (img) => {
    setImage(img);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handlePetitionClick = () => {
    const width = 700;
    const height = 500;
    const left = (window.screen.width / 2) - (width / 2);
    const top = (window.screen.height / 2) - (height / 2);

    window.open(
      'https://form.jotform.com/242154066140143',
      'blank',
      `scrollbars=yes,toolbar=no,width=${width},height=${height},top=${top},left=${left}`
    );
  };

  return (
    <Box my={4}>
      <Container>
        <Typography variant="h4" component="h2" gutterBottom>
          Proposed Campground Site
        </Typography>
        <Button variant="contained" color="primary" onClick={() => handleOpen('/imgs/lot_location.png')} style={{ marginRight: '10px' }}>
          Show Lot Location
        </Button>
        <Button variant="contained" color="secondary" onClick={() => handleOpen('/imgs/future_state.png')} style={{ marginRight: '10px' }}>
          Show Proposed State
        </Button>
        <Button variant="contained" color="success" onClick={handlePetitionClick}>
          Sign the Petition
        </Button>
        <Modal open={open} onClose={handleClose}>
          <Box sx={style}>
            <img src={image} alt="Proposed Campground" style={{ width: '100%' }} />
          </Box>
        </Modal>
        <Typography variant="h6" component="p" mt={4}>
        Location: The proposed campground site is located behind 36 Little Falls Bridge Rd. Tax Map 209 Lot 8 & 9.
        </Typography>
        <Typography variant="body1" component="p" mt={2}>
        As seen by the location of the campground, it will be in close proximity to the Cocheco River. The river is a popular spot for fishing, kayaking, and other recreational activities. The campground will be in a rural area with a mix of residential and commercial properties. This will impact us in many ways, including traffic, noise, and environmental concerns. The proponents claim no negative affects, but do not be fooled. This is backed by no empirical evidence. Their profits will be capitalized and all the headaches will be socialized on to the residents and community.
        </Typography>
      </Container>
    </Box>
  );
}

export default SiteDetails;