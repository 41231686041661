import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

function Navbar() {
  return (
    <AppBar position="static">
      <Toolbar>
        <Container>
          <Typography variant="h6">
            Community Over Profits
          </Typography>
        </Container>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;