import React from 'react';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import Concern from './components/Concern';
import Footer from './components/Footer';
import ActionButton from './components/ActionButton';
import MeetingInfo from './components/MeetingInfo';
import SiteDetails from './components/SiteDetails';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';

function App() {
  return (
    <div>
      <Navbar />
      <HeroSection />
      <Container>
        <MeetingInfo />
        <Divider variant="middle" />
        <SiteDetails />
        <Divider variant="middle" />
        <Concern
          title="Environmental Concerns"
          content="Campgrounds can negatively affect local ecosystems, water quality, and air quality. The EPA stresses the importance of thorough Environmental Impact Assessments (EIA) to understand these potential damages. Increased human activity can disrupt local wildlife, degrade the scenic and environmental quality of the area, and lead to pollution of the Cocheco River."
        />
        <Divider variant="middle" />
        <Concern
          title="Traffic and Noise Impact"
          content="The proposal claims minimal traffic impact, but this assumption lacks empirical support. Peak times could lead to significant congestion, increasing accident risks and affecting pedestrian safety. Noise from campgrounds can travel far, disturbing residents' peace, particularly affecting young children."
        />
        <Divider variant="middle" />
        <Concern
          title="Property Values"
          content="Research shows that developments such as campgrounds can lead to decreased property values due to noise, increased traffic, and other nuisances. Studies on RV parks and similar high-impact developments have documented significant drops in property values."
        />
        <Divider variant="middle" />
        <Concern
          title="Infrastructure and Safety"
          content="The actual implementation and maintenance of utilities could prove disruptive. There may be unforeseen complications with water, sewer, and power utilities, especially in remote areas. Ensuring emergency access and adequate infrastructure is crucial."
        />
        <Divider variant="middle" />
        <Concern
          title="Impact on First Responders"
          content="The development could lead to a higher number of incidents requiring the attention of our first responders, including firemen and police officers. Increased traffic accidents and potential rise in crime rates will place additional demands on their services."
        />
        <Divider variant="middle" />
        <Concern
          title="Consistency with Community Goals"
          content="While the proponent argues that the campground aligns with the Master Plan by promoting diverse land uses and economic activity, economic benefits must be weighed against potential declines in property values and environmental and social costs. The development should integrate sustainably with the community."
        />
      </Container>
      <Footer />
      <ActionButton />
    </div>
  );
}

export default App;